/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

// ConfirmationModal component

import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const ConfirmationModal = ({
  showConfirmation,
  setShowConfirmation,
  message,
  handleClose,
}) => {
  useEffect(() => {
    if (showConfirmation) {
      // Automatically close the modal after 3 seconds
      const timeoutId = setTimeout(() => {
        setShowConfirmation(false);
        handleClose();
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [showConfirmation, setShowConfirmation, handleClose]);

  return (
    <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setShowConfirmation(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
