import React from "react";
import magOne from "./../images/mag.JPG";
import magTwo from "./../images/mag1.JPG";
import magThree from "./../images/mag2.JPG";
import magFour from "./../images/mag3.JPG";
import magFive from "./../images/mag4.JPG";
import magSix from "./../images/mag5.JPG";
import magSeven from "./../images/mag6.JPG";
import magEight from "./../images/mag7.JPG";
import "bootstrap/dist/css/bootstrap.min.css";

function Magazine() {
  const magazineImG = [
    { Id: 1, src: magOne, alt: "magazine-image" },
    { Id: 2, src: magTwo, alt: "magazine-image" },
    { Id: 3, src: magThree, alt: "magazine-image" },
    { Id: 4, src: magFour, alt: "magazine-image" },
    { Id: 5, src: magFive, alt: "magazine-image" },
    { Id: 6, src: magSix, alt: "magazine-image" },
    { Id: 7, src: magSeven, alt: "magazine-image" },
    { Id: 8, src: magEight, alt: "magazine-image" },
  ];
  return (
    <div>
      {magazineImG.map((image) => (
        <div
          key={image.Id}
          className="d-flex justify-content-center align-items-center container"
        >
          <img src={image.src} alt={image.alt} className="m-3 w-75" />
        </div>
      ))}
    </div>
  );
}

export default Magazine;
