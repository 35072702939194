import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Registration from "./pages/Registration";
import Magazine from "./pages/Magazine";
import { Home } from "./pages/Home";
import Topnav from "./component/Topnav";
import Navigation from "./component/Navigation";
import Footer from "./component/Footer";
import BackToTopbtn from "./component/BackToTopbtn";
import "./App.scss";

const App = () => {
  return (
    <>
      <Router>
        <Topnav />
        <Navigation />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/magazine" exact element={<Magazine />} />
          <Route path="/registration" exact element={<Registration />} />
        </Routes>
        <Footer />
        <BackToTopbtn />
      </Router>
    </>
  );
};

export default App;
