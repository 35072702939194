import React from "react";
import { useState } from "react";
import axios from "axios";
import ConfirmationModal from "../component/ConfirmModal";
import "bootstrap/dist/css/bootstrap.min.css";
// import Loader from "../component/Loader";

const initialFormData = {
  FirstName: "",
  lastname: "",
  Email: "",
  inputAddress: "",
  Specialty: "",
  studentNumber: "",
  dob: "",
  placeOfBirth: "",
  region: "",
  gender: "",
  ParentName: "",
  ParentNumber: "",
};

const Registration = () => {
  const [formData, setFormData] = useState(initialFormData);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errors, setErrors] = useState({});

  //handle change functions

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleGenderChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      gender: value,
    }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: id === "Specialty" ? String(value) : value,
    }));
  };

  //function to handle submit

  const handleSubmit = async (e) => {
    e.preventDefault();
    //basic formvalidation

    const newErrors = {};

    const phoneRegex = /^\d{9}$/;
    if (
      !formData.studentNumber.trim() ||
      !phoneRegex.test(formData.studentNumber.trim())
    ) {
      newErrors.studentNumber = "Phone number should be 9 digits";
    }

    if (
      !formData.ParentNumber.trim() ||
      !phoneRegex.test(formData.ParentNumber.trim())
    ) {
      newErrors.ParentNumber = "Phone number should be 9 digits";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.Email.trim() || !emailRegex.test(formData.Email.trim())) {
      newErrors.Email = "Please enter a valid email address";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        await axios.post(
          "https://ghibbatbafia.onrender.com/api/register",
          formData
        );

        setConfirmationMessage("Form submitted successfully!");
        console.log(formData);
        setShowConfirmation(true);
        setTimeout(handleCloseConfirmation, 3000);
        setFormData(initialFormData);
      } catch (error) {
        // setShowLoader(false);
        setConfirmationMessage("Form not submitted successfully!");
        setShowConfirmation(true);
      }
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
    setConfirmationMessage("");
  };

  return (
    <form
      className="row g-3 "
      style={{ margin: "0 auto", maxWidth: "600px" }}
      // onSubmit={handleSubmit}
    >
      <h1>REGISTER HERE</h1>
      <div className="col-md-6">
        <label htmlFor="FirstName" className="form-label">
          FirstName
        </label>
        <input
          type="text"
          className="form-control"
          id="FirstName"
          value={formData.FirstName}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="lastname" className="form-label">
          lastname
        </label>
        <input
          type="text"
          className="form-control"
          id="lastname"
          value={formData.lastname}
          onChange={handleChange}
        />
      </div>
      <div className="col-12">
        <label htmlFor="Email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className={`form-control ${errors.Email ? "is-invalid" : ""}`}
          id="Email"
          value={formData.Email}
          onChange={handleChange}
        />
        {errors.Email && <div className="invalid-feedback">{errors.Email}</div>}
      </div>
      <div className="col-6">
        <label htmlFor="Address" className="form-label">
          Address
        </label>
        <input
          type="text"
          className="form-control"
          id="inputAddress"
          value={formData.inputAddress}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <label htmlFor="Specialty" className="form-label">
          Specialty
        </label>
        <select
          id="Specialty"
          value={formData.Specialty}
          onChange={handleInputChange}
          className="form-select"
        >
          <option value="">--select specialty--</option>
          <option value="Agri">Agriculture</option>
          <option value="Edu">Education</option>
          <option value="EngTech">Engineering and Technology</option>
          <option value="HBioMed">Health and Biomedical sciences</option>
          <option value="BFM">Business, Finance and Management</option>
          {/* Add other options as needed */}
        </select>
      </div>
      <div className="col-md-6">
        <label htmlFor="studentNumber" className="form-label">
          Student Number
        </label>
        <input
          type="text"
          className={`form-control ${errors.studentNumber ? "is-invalid" : ""}`}
          id="studentNumber"
          value={formData.studentNumber}
          onChange={handleChange}
        />
        {errors.studentNumber && (
          <div className="invalid-feedback">{errors.studentNumber}</div>
        )}
      </div>
      <div className="col-md-6">
        <label htmlFor="dob" className="form-label">
          Date of Birth
        </label>
        <input
          type="date"
          className="form-control"
          id="dob"
          value={formData.dob}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <label htmlFor="placeOfBirth" className="form-label">
          Place of Birth
        </label>
        <input
          type="text"
          className="form-control"
          id="placeOfBirth"
          value={formData.placeOfBirth}
          onChange={handleChange}
        />
      </div>
      <div className="col-6">
        <label htmlFor="region" className="form-label">
          Region of origin
        </label>
        <input
          type="text"
          className="form-control"
          id="region"
          value={formData.region}
          onChange={handleChange}
        />
      </div>
      <div className="col-12">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="gender"
            id="maleGender"
            value="Male"
            checked={formData.gender === "Male"}
            onChange={handleGenderChange}
          />
          <label className="form-check-label" htmlFor="maleGender">
            Male
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="gender"
            id="femaleGender"
            value="female"
            checked={formData.gender === "female"}
            onChange={handleGenderChange}
          />
          <label className="form-check-label" htmlFor="femaleGender">
            Female
          </label>
        </div>
      </div>
      <div className="col-md-6">
        <label htmlFor="ParentName" className="form-label">
          ParentName
        </label>
        <input
          type="text"
          className="form-control"
          id="ParentName"
          value={formData.ParentName}
          onChange={handleChange}
        />
      </div>
      <div className="col-md-6">
        <label htmlFor="ParentNumber" className="form-label">
          Parent Number
        </label>
        <input
          type="text"
          className={`form-control ${errors.ParentNumber ? "is-invalid" : ""}`}
          id="ParentNumber"
          value={formData.ParentNumber}
          onChange={handleChange}
        />
        {errors.ParentNumber && (
          <div className="invalid-feedback">{errors.ParentNumber}</div>
        )}
      </div>
      <div className="col-12 d-grid gap-2 d-md-block">
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary py-2 px-4 d-lg-block"
        >
          Submit
        </button>

        {/* {showLoader && <Loader />} */}
        {/* Confirmation Modal */}
        <ConfirmationModal
          showConfirmation={showConfirmation}
          setShowConfirmation={setShowConfirmation}
          message={confirmationMessage}
          handleClose={handleCloseConfirmation}
        />
      </div>
    </form>
  );
};

export default Registration;
