import React from "react";
import Header from "../component/Header";
import About from "../component/About";
import Feature from "../component/Feature";
import Specialty from "../component/Specialty";
import Administration from "../component/Ghibbatadmin";
import Activities from "../component/Activities";
import Contact from "../component/Contact";

export const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Feature />
      <Specialty />
      <Administration />
      <Activities />
      <Contact />
    </>
  );
};
