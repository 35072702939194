/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React from "react";
import Admin1 from "../images/Admin1.jpeg";
import Admin2 from "../images/Admin2.jpeg";
import Admin3 from "../images/Admin3.jpeg";
import Admin4 from "../images/Admin4.jpeg";
import Admin5 from "../images/Admin5.jpg";
import Admin6 from "../images/Admin6.jpg";
import Admin7 from "../images/Admin7.jpg";
import Admin8 from "../images/Admin8.jpg";
import Admin9 from "../images/Admin9.jpg";
import Admin10 from "../images/Admin10.jpeg";
import Admin11 from "../images/Admin11.jpg";
import "../App.scss";
import Carousel from "react-multi-carousel";
const Gdmin = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div
          className="section-title text-center position-relative mb-5"
          id="admin"
        >
          <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
            Administration
          </h6>
          <h1 className="display-4">Meet Our Core Administration</h1>
        </div>
        <Carousel
          responsive={responsive}
          containerClass="admin-container"
          partialVisbile={false}
          autoPlay={true}
          infinite={true}
          // itemClass="carousel-item"
          partialVisible={false}
        >
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin1}
              alt="Mr Time David promoter school manager"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Hon. Tim David N.</h5>
              <p className="mb-2">Manager and Promoter of GHIBBAT BAFIA</p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin2}
              alt="Chief of the Finance/Business department"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3"> Dr Abessougui Bis</h5>
              <p className="mb-2">Chief of Finance/Business department </p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin3}
              alt="Chief of the Biomedical departmentl"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Dr Pierre Dadam </h5>
              <p className="mb-2">Chief of the Biomedical department</p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin4}
              alt="Chief of sciences and education department"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Bodongono Pierre</h5>
              <p className="mb-2">Chief of sciences and education department</p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin5}
              alt="Chief of the District Hospital Bafia"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Dr Eteme Pierre</h5>
              <p className="mb-2">Chief of the District Hospital Bafia</p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin6}
              alt="Chief of sciences and education department"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">DAAC Mme Elisabeth</h5>
              <p className="mb-2">Chief of sciences and education department</p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin7}
              alt="Medical and Laboratory Technical Analys"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Mr Besong Yves</h5>
              <p className="mb-2">Medical and Laboratory Technical Analys</p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin8}
              alt=" Chief of Road and Civil Engineering department"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Mr Kilian Tanwie Ntem</h5>
              <p className="mb-2">
                Chief of Road and Civil Engineering department
              </p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin9}
              alt="Chief of Adjoin Gestion Business department"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Mr Gambo Lucas</h5>
              <p className="mb-2">
                Chief of Adjoin Gestion Business department
              </p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin10}
              alt="of Agronomy in the department of Agricultur"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3"> Enigeneer Tchute Zelieu </h5>
              <p className="mb-2">
                of Agronomy in the department of Agricultur
              </p>
            </div>
          </div>
          <div className="team-item">
            <img
              className="img-fluid w-100 h-100"
              src={Admin11}
              alt="Chief of the department Agicullture"
            />
            <div className="bg-light text-center p-4">
              <h5 className="mb-3">Engineer Fotso Kamga</h5>
              <p className="mb-2">Chief of the department Agicullture </p>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Gdmin;
