/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React from "react";
import coursOne from "../images/department1.JPG";
import coursTwo from "../images/department2.JPG";
import coursThree from "../images/department3.JPG";
import coursFour from "../images/department4.JPG";
import coursFive from "../images/department5.JPG";
// import { FaStar, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../App.scss";

const Specialty = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="container-fluid px-0 py-5">
      <div className="row mx-0 justify-content-center pt-5">
        <div className="col-lg-6">
          <div
            className="section-title text-center position-relative mb-4"
            id="department"
          >
            <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
              Our Departments
            </h6>
            <h1 className="display-4">Checkout Our Available Departments</h1>
          </div>
        </div>
      </div>
      <Carousel
        responsive={responsive}
        // containerClass="carousel-container"
        partialVisbile={false}
        autoPlay={true}
        infinite={true}
        arrows={false}
      >
        <div className="courses-item position-relative">
          <img
            className="img-fluid"
            src={coursOne}
            alt="department of education"
          />
          <div className="courses-text">
            <h4 className="text-center text-white px-3">School of education</h4>
            <div className="border-top w-100 mt-3">
              <div className="d-flex justify-content-between p-4"></div>
            </div>
            <div className="w-100 bg-white text-center p-4">
              <button className="btn btn-primary">Available</button>
            </div>
          </div>
        </div>
        <div className="courses-item position-relative">
          <img
            className="img-fluid"
            src={coursTwo}
            alt="department of business and finance"
          />
          <div className="courses-text">
            <h4 className="text-center text-white px-3">
              School of Business, finance and Management
            </h4>
            <div className="border-top w-100 mt-3">
              <div className="d-flex justify-content-between p-4"></div>
            </div>
            <div className="w-100 bg-white text-center p-4">
              <button className="btn btn-primary">Available</button>
            </div>
          </div>
        </div>
        <div className="courses-item position-relative">
          <img
            className="img-fluid"
            src={coursThree}
            alt="department of agriculture"
          />
          <div className="courses-text">
            <h4 className="text-center text-white px-3">
              School of Agriculture
            </h4>
            <div className="border-top w-100 mt-3">
              <div className="d-flex justify-content-between p-4"></div>
            </div>
            <div className="w-100 bg-white text-center p-4">
              <button className="btn btn-primary">Available</button>
            </div>
          </div>
        </div>
        <div className="courses-item position-relative">
          <img
            className="img-fluid"
            src={coursFour}
            alt="department of engineering"
          />
          <div className="courses-text">
            <h4 className="text-center text-white px-3">
              School of Engineering and Technology
            </h4>
            <div className="border-top w-100 mt-3">
              <div className="d-flex justify-content-between p-4"></div>
            </div>
            <div className="w-100 bg-white text-center p-4">
              <button className="btn btn-primary">Available</button>
            </div>
          </div>
        </div>
        <div className="courses-item position-relative">
          <img
            className="img-fluid"
            src={coursFive}
            alt="department of health and biomeducal sciences"
          />
          <div className="courses-text">
            <h4 className="text-center text-white px-3">
              School of Health and Biomedical sciences
            </h4>
            <div className="border-top w-100 mt-3">
              <div className="d-flex justify-content-between p-4"></div>
            </div>
            <div className="w-100 bg-white text-center p-4">
              <button className="btn btn-primary">Available</button>
            </div>
          </div>
        </div>
      </Carousel>

      <div className="row bg-image mx-0 mb-5">
        <div className="col-lg-12 d-flex align-items-center justify-content-center py-5">
          <div className="bg-white p-5 my-5">
            <h1 className="text-center mb-4">Join us at GHIBBAT BAFIA!</h1>
            <div className="col-sm-4 mx-auto">
              <Link
                to="/registration"
                className="btn btn-primary"
                style={{
                  height: "60px",
                  width: "200px",
                  fontSize: "20px",
                  paddingTop: "15px",
                  borderRadius: "5px",
                }}
              >
                REGISTER NOW
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specialty;
