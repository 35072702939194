/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import the Bootstrap CSS
import {
  FaPhoneAlt,
  FaEnvelope,
  FaTwitter,
  FaFacebook,
  FaLinkedin,
} from "react-icons/fa"; // Import React Icon
import "../App.scss";

const Topnav = () => {
  return (
    <div className="container-fluid bg-dark">
      <div className="row py-2 px-lg-5 justify-content-between">
        <div className="col-lg-4 text-center mb-2 mb-lg-0">
          <div className="d-inline-flex align-items-center text-white">
            <small>
              <FaPhoneAlt className="mr-2" />
              +237694209097
            </small>
            <small className="px-3">|</small>
            <small>
              <FaEnvelope className="mr-2" />
              ghibbatbafia@gmail.com
            </small>
          </div>
        </div>
        <div className="col-lg-2 text-center text-lg-right">
          <div className="d-inline-flex align-items-center">
            <a
              className="text-white px-2"
              href="https://m.facebook.com/Central-University-Institute-Bafia-CUIBa-100435532605300/?ref=page_internal"
            >
              <FaFacebook />
            </a>
            <a className="text-white px-2" href="javascript:void(0)">
              <FaTwitter />
            </a>
            <a className="text-white px-2" href="javascript:void(0)">
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topnav;
