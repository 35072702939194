/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React, { useState, useEffect } from "react";
import logo from "../images/Logo.jpg";
import pdfr from "../Calender.pdf";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";

const Navigation = () => {
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const [registration, setRegistration] = useState(false);
  const [magazine, setMagazine] = useState(false);
  const location = useLocation();

  const handleToggle = () => {
    setisMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setisMenuOpen(false);
  };

  useEffect(() => {
    const isRegistrationPage = location.pathname === "/registration";
    setRegistration(isRegistrationPage);
  }, [location.pathname]);

  useEffect(() => {
    const isMagazinePage = location.pathname === "/magazine";
    setMagazine(isMagazinePage);
  }, [location.pathname]);

  return (
    <>
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg bg-white navbar-light py-3 py-lg-0 px-lg-5">
          <a href="/" className="navbar-brand ml-lg-3">
            <img
              src={logo}
              width={150}
              height={100}
              style={{ marginLeft: "25px" }}
              alt="logo"
            />
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            style={{ marginRight: "30px" }}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-between px-lg-3 ${
              isMenuOpen ? "show" : ""
            }`}
          >
            <div className="navbar-nav mx-auto py-0 myPointer">
              <a href="/" className="nav-item nav-link active">
                Home
              </a>
              <RouterLink
                to="/magazine"
                onClick={closeMenu}
                className={`nav-item nav-link active ${
                  magazine ? "disabled" : ""
                }`}
              >
                Magazine
              </RouterLink>
              <a
                href="#about"
                onClick={closeMenu}
                className={`nav-item nav-link ${
                  registration || magazine ? "disabled" : ""
                }`}
                style={{
                  color:
                    registration || magazine
                      ? "rgba(169, 169, 169, 0.5)"
                      : "none",
                  pointerEvents: registration || magazine ? "none" : "auto",
                }}
              >
                About
              </a>
              <a
                href="#activity"
                onClick={closeMenu}
                className={`nav-item nav-link ${
                  registration || magazine ? "disabled" : ""
                }`}
                style={{
                  color:
                    registration || magazine
                      ? "rgba(169, 169, 169, 0.5)"
                      : "none",
                  pointerEvents: registration || magazine ? "none" : "auto",
                }}
              >
                Activities
              </a>
              <a
                href="#department"
                onClick={closeMenu}
                className={`nav-item nav-link ${
                  registration || magazine ? "disabled" : ""
                }`}
                style={{
                  color:
                    registration || magazine
                      ? "rgba(169, 169, 169, 0.5)"
                      : "none",
                  pointerEvents: registration || magazine ? "none" : "auto",
                }}
              >
                Department
              </a>
              <a
                href="#admin"
                onClick={closeMenu}
                className={`nav-item nav-link ${
                  registration || magazine ? "disabled" : ""
                }`}
                style={{
                  color:
                    registration || magazine
                      ? "rgba(169, 169, 169, 0.5)"
                      : "none",
                  pointerEvents: registration || magazine ? "none" : "auto",
                }}
              >
                Aministration
              </a>
              <a
                href="#contact"
                onClick={closeMenu}
                className={`nav-item nav-link ${
                  registration || magazine ? "disabled" : ""
                }`}
                style={{
                  color:
                    registration || magazine
                      ? "rgba(169, 169, 169, 0.5)"
                      : "none",
                  pointerEvents: registration || magazine ? "none" : "auto",
                }}
              >
                Contact
              </a>
              <a
                href={pdfr}
                onClick={closeMenu}
                target="_blank"
                rel="noreferrer"
                download={pdfr}
                className="nav-item nav-link"
              >
                Calendar
              </a>
            </div>
            <RouterLink
              to="/registration"
              onClick={closeMenu}
              className={`btn btn-primary py-2 px-4 d-lg-block sidebar-btn  ${
                registration ? "disabled" : ""
              }`}
              style={{
                color: registration ? "rgba(169, 169, 169, 0.5)" : "none",
                pointerEvents: registration ? "none" : "auto",
              }}
            >
              Register
            </RouterLink>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navigation;
