/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.scss";

const Header = () => {
  const text =
    "Welcome to Grace Higher Institute for Biomedical Business and Technology";
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    let typingInterval;

    const typeText = () => {
      if (currentIndex <= text.length) {
        setDisplayText(text.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(typingInterval); // Clear the interval to stop typing
        setTimeout(() => {
          currentIndex = 0;
          typingInterval = setInterval(typeText, 100);
        }, 1000); // Pause for 1 second and start typing text1
      }
    };

    typingInterval = setInterval(typeText, 100); // Adjust typing speed here

    return () => {
      clearInterval(typingInterval);
    };
  }, []);

  return (
    <div
      className="jumbotron jumbotron-fluid position-relative overlay-bottom"
      style={{ marginBottom: "90px" }}
    >
      <div className="container text-center my-5 py-5">
        <h1 className="text-white mt-4 mb-5">
          {displayText.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {index > 0 && <br />} {line}
            </React.Fragment>
          ))}
        </h1>
        <h1 className="text-white display-1 mb-5">
          Youth Development For a Brighter Tomorrow
        </h1>
        <div
          className="mx-auto mb-5"
          style={{ width: "100%", maxWidth: "600px" }}
        ></div>
      </div>
    </div>
  );
};

export default Header;
