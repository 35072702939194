/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React from "react";
import myGImage1 from "../images/DSCN5435.JPG";
import myGImage2 from "../images/DSCN6678.JPG";
import myGImage3 from "../images/DSCN6815.JPG";
import myGImage4 from "../images/Activity1.jpg";
import myGImage5 from "../images/Activity2.jpg";
import myGImage6 from "../images/Activity3.jpg";
import myGImage7 from "../images/Activity5.jpg";
import myGImage8 from "../images/Activity6.jpg";
import myGImage9 from "../images/Activity7.jpg";
import myGImage10 from "../images/Activity8.jpg";
import myGImage11 from "../images/Activity9.jpg";
import myGImage12 from "../images/Activity10.JPG";
import myGImage13 from "../images/Activity12.JPG";
import myGImage14 from "../images/Activity13.JPG";
import myGImage15 from "../images/Activity14.JPG";
import myGImage16 from "../images/Activity15.JPG";
import myGImage17 from "../images/Activity16.jpeg";
import myGImage18 from "../images/Activity17.JPG";
import myGImage19 from "../images/Activity18.JPG";
import myGImage20 from "../images/Activity19.JPG";
import myGImage21 from "../images/Activity20.JPG";
import myGImage22 from "../images/Activity21.JPG";
import myGImage23 from "../images/Activity22.JPG";
import myGImage24 from "../images/Activity23.JPG";
import myGImage25 from "../images/Activity24.JPG";
import myGImage26 from "../images/Activity25.JPG";
import myGImage27 from "../images/Activity26.JPG";
import myGImage28 from "../images/Activity27.JPG";
import myGImage29 from "../images/Activity28.JPG";
import myGImage30 from "../images/Activity29.JPG";
import Carousel from "react-multi-carousel";

import "../App.scss";

const Activities = () => {
  const imageList = [
    { id: 1, src: myGImage1, alt: "school sctivities" },
    { id: 2, src: myGImage2, alt: "school sctivities" },
    { id: 3, src: myGImage3, alt: "school sctivities" },
    { id: 4, src: myGImage4, alt: "school sctivities" },
    { id: 5, src: myGImage5, alt: "school sctivities" },
    { id: 6, src: myGImage6, alt: "school sctivities" },
    { id: 7, src: myGImage7, alt: "school sctivities" },
    { id: 8, src: myGImage8, alt: "school sctivities" },
    { id: 9, src: myGImage9, alt: "school sctivities" },
    { id: 10, src: myGImage10, alt: "school sctivities" },
    { id: 11, src: myGImage11, alt: "school sctivities" },
    { id: 12, src: myGImage12, alt: "school sctivities" },
    { id: 13, src: myGImage13, alt: "school sctivities" },
    { id: 14, src: myGImage14, alt: "school sctivities" },
    { id: 15, src: myGImage15, alt: "school sctivities" },
    { id: 16, src: myGImage16, alt: "school sctivities" },
    { id: 17, src: myGImage17, alt: "school sctivities" },
    { id: 18, src: myGImage18, alt: "school sctivities" },
    { id: 19, src: myGImage19, alt: "school sctivities" },
    { id: 20, src: myGImage20, alt: "school sctivities" },
    { id: 21, src: myGImage21, alt: "school sctivities" },
    { id: 22, src: myGImage22, alt: "school sctivities" },
    { id: 23, src: myGImage23, alt: "school sctivities" },
    { id: 24, src: myGImage24, alt: "school sctivities" },
    { id: 25, src: myGImage25, alt: "school sctivities" },
    { id: 26, src: myGImage26, alt: "school sctivities" },
    { id: 27, src: myGImage27, alt: "school sctivities" },
    { id: 28, src: myGImage28, alt: "school sctivities" },
    { id: 29, src: myGImage29, alt: "school sctivities" },
    { id: 30, src: myGImage30, alt: "school sctivities" },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="container-fluid bg-image py-5" style={{ margin: "90px 0" }}>
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div className="section-title position-relative mb-4" id="activity">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
                Gallery and Activities
              </h6>
              <h1 className="display-4">Our Vibrants Environment</h1>
            </div>
            <p className="m-0">
              At GHIBBAT BAFIA, our campus is not just a place for studying;
              it's a vibrant community where learning goes beyond textbooks.
              Engage in a myriad of activities that bring our campus to life,
              including Cultural Week, Bilingualism Day, Youth Week,
              Matriculation Day, and more. Immerse yourself in a dynamic
              environment that fosters not only academic growth but also
              celebrates the richness of diverse experiences. Join us for an
              education that goes beyond the traditional, where every day is
              filled with exciting events that enhance your college experience.
            </p>
          </div>
          <div className="col-lg-7">
            <Carousel
              responsive={responsive}
              partialVisbile={false}
              autoPlay={true}
              infinite={true}
              arrows={false}
            >
              {imageList.map((image) => (
                <div key={image.id} className="bg-white p-5">
                  <div className="d-flex flex-shrink-0 align-items-center mt-4">
                    <img
                      className="img-fluid"
                      src={image.src}
                      alt={image.alt}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activities;
