/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree in software engineering

*/

import React from "react";
import Abt from "../images/Gimg.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import CountUp from "react-countup";
import "../App.scss";

const About = () => {
  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: "500px" }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100"
                src={Abt}
                style={{ objectFit: "cover" }}
                alt="propritor of school"
              />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="section-title position-relative mb-4" id="about">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
                About Us
              </h6>
              <h1 className="display-4">
                First Choice For Private Higher Institution
              </h1>
            </div>
            <p>
              Grace Higher Institute, founded in October 2019, is committed to
              providing professional education for individuals in the Northwest
              and Southwest regions that are internally displaced and to the
              center region at large. Our vision Youth Development for a
              brighter tomorrow empower and enrich their minds and lives through
              a conducive learning environment, fostering intellectual curiosity
              and innovative thinking. With a focus on academic excellence and
              holistic development, our diverse faculties cover agriculture,
              health and biomedical sciences, business finance and management,
              engineering and technology, and education. In <b>agriculture</b>,
              we lead in cultivating knowledge and sustainable practices, while
              our <b>health and biomedical sciences</b> faculty shapes the next
              generation of healthcare professionals. In the dynamic fields of
              <b>business, finance, and management</b>, we equip students with
              essential skills, and our <b>engineering and technology</b>{" "}
              faculty embraces innovation. Finally, our <b>education</b> faculty
              is dedicated to preparing inspirational educators who shape the
              minds of future generations with effective teaching methods and
              educational leadership.
            </p>
            <div className="row pt-3 mx-0">
              <div className="col-md-3 col-12  px-0 mb-3">
                <div className="bg-success text-center p-4">
                  <h1 className="text-white">
                    <CountUp
                      enableScrollSpy={true}
                      start={0}
                      end={7}
                      duration={3}
                    />
                  </h1>
                  <h6 className="text-uppercase text-white">
                    Available<span className="d-block">Specialties</span>
                  </h6>
                </div>
              </div>
              <div className="col-md-3 col-12  px-0 mb-3">
                <div className="bg-primary text-center p-4">
                  <h1 className="text-white">
                    <CountUp
                      enableScrollSpy={true}
                      start={0}
                      end={1234}
                      duration={3}
                    />
                  </h1>
                  <h6 className="text-uppercase text-white">
                    Available<span className="d-block">Courses</span>
                  </h6>
                </div>
              </div>
              <div className="col-md-3 col-12  px-0 mb-3">
                <div className="bg-secondary text-center p-4">
                  <h1 className="text-white">
                    <CountUp
                      enableScrollSpy={true}
                      start={0}
                      end={20}
                      duration={3}
                    />
                  </h1>
                  <h6 className="text-uppercase text-white">
                    Skilled<span className="d-block">Instructors</span>
                  </h6>
                </div>
              </div>
              <div className="col-md-3 col-12  px-0 mb-3">
                <div className="bg-warning text-center p-4">
                  <h1 className="text-white">
                    <CountUp
                      enableScrollSpy={true}
                      start={0}
                      end={500}
                      duration={3}
                    />
                  </h1>
                  <h6 className="text-uppercase text-white">
                    Happy<span className="d-block">Students</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
