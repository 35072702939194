/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React from "react";
import Fimg from "../images/matriculation.jpeg";
import "../App.scss";
import { FaBookReader, FaCertificate, FaGraduationCap } from "react-icons/fa";

const Feature = () => {
  return (
    <div className="container-fluid bg-image" style={{ margin: "90px 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 my-5 pt-5 pb-lg-5">
            <div className="section-title position-relative mb-4">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
                Why Choose Us?
              </h6>
              <h1 className="display-4">
                Why You Should Start Learning with Us?
              </h1>
            </div>
            <p className="mb-4 pb-2">
              Grace Higher Institute is committed to delivering high-quality
              education, facilitated by skilled instructors with expertise in
              their respective fields.With state-of-the-art facilities,
              including cutting-edge laboratories and libraries, we provide an
              environment conducive to research and learning. Beyond academics,
              we focus on holistic development, nurturing the personal and
              professional growth of our students.
            </p>
            <div className="d-flex mb-3">
              <div
                className="btn-icon bg-primary mr-4"
                style={{ marginRight: "20px" }}
              >
                <FaGraduationCap className="text-white" size={50} />
              </div>
              <div className="mt-n1">
                <h4>Skilled Instructors</h4>
                <p>
                  Our faculty members are experts in their respective fields,
                  bringing a wealth of knowledge and practical experience to the
                  classroom.
                </p>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div
                className="btn-icon bg-secondary mr-4"
                style={{ marginRight: "20px" }}
              >
                <FaCertificate className="text-white" size={50} />
              </div>
              <div className="mt-n1">
                <h4>HND/Professional Bachelors Degree </h4>
                <p>
                  HND and professional degree certificates, brings practical
                  knowledge to the classroom, enriching the learning experience
                  with<span className="excellent">100% excellence</span> in the
                  HND 2021/2022 Session
                </p>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="btn-icon bg-warning mr-4"
                style={{ marginRight: "20px" }}
              >
                <FaBookReader className="text-white" size={50} />
              </div>
              <div className="mt-n1">
                <h4>Equiped laboratries</h4>
                <p className="m-0">
                  state-of-the-art facilities, including cutting-edge
                  laboratories and libraries, we provide an environment
                  conducive for research and learning.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5" style={{ minHeight: "500px" }}>
            <div className="position-relative h-100">
              <img
                className="position-absolute w-100 h-100"
                src={Fimg}
                style={{ objectFit: "cover" }}
                alt="administration"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
