import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FaArrowCircleUp } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";

const BackToTopbtn = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Add scroll event listener to show/hide the button
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 300; // Adjust this threshold as needed

      setShowButton(scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Button
      onClick={scrollToTop}
      className={`scroll-top-btn ${showButton ? "visible" : "invisible"}`}
    >
      <FaArrowCircleUp />
    </Button>
  );
};

export default BackToTopbtn;
