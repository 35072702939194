/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

import React from "react";
import "../App.scss";
import { FaEnvelope, FaMapMarked, FaPhone } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="container-fluid py-5">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-lg-5 mb-5 mb-lg-0">
            <div
              className="bg-light d-flex flex-column justify-content-center px-3"
              style={{ height: "450px" }}
            >
              <div className="d-flex align-items-center mb-5">
                <div
                  className="btn-icon bg-primary mr-4"
                  style={{ marginRight: "10px" }}
                >
                  <FaMapMarked className="text-white" size={50} />
                </div>
                <div className="mt-n1">
                  <h4>Our Location</h4>
                  <p className="m-0">Bafia yaounde Cameroon</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-5">
                <div
                  className="btn-icon bg-secondary mr-4"
                  style={{ marginRight: "10px" }}
                >
                  <FaPhone className="text-white" size={50} />
                </div>
                <div className="mt-n1">
                  <h4>Call Us</h4>
                  <p className="m-0">675118318</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="btn-icon bg-warning mr-2"
                  style={{ marginRight: "10px" }}
                >
                  <FaEnvelope className="text-white" size={50} />
                </div>
                <div className="mt-n2">
                  <h4>Email Us</h4>
                  <p className="m-0">ghibbatbafia@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="section-title position-relative mb-4" id="contact">
              <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">
                Need Help?
              </h6>
              <h1 className="display-4">Send Us A Message</h1>
            </div>
            <div className="contact-form">
              <form>
                <div className="row">
                  <div className="col-6 form-group mb-4">
                    <input
                      type="text"
                      className="form-control formDesign p-0"
                      placeholder="Your Name"
                      required="required"
                    />
                  </div>
                  <div className="col-6 form-group mb-4">
                    <input
                      type="email"
                      className="form-control p-0 formDesign"
                      placeholder="Your Email"
                      required="required"
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <input
                    type="text"
                    className="form-control formDesign p-0"
                    placeholder="Subject"
                    required="required"
                  />
                </div>
                <div className="form-group mb-4">
                  <textarea
                    className="form-control formDesign p-0"
                    rows="5"
                    placeholder="Message"
                    required="required"
                  ></textarea>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <button className="btn btn-primary py-3 px-5" type="submit">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
