import React from "react";
import "../App.scss";
import { Link } from "react-router-dom";
import schoolBuilding from "../images/Campus.jpg";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid position-relative overlay-top bg-dark text-white-50 py-5"
        style={{ marginTop: "90px" }}
      >
        <div className="container mt-5 pt-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex align-items-center justify-content-center py-5">
              <div className="bg-white p-2 my-2 text-center">
                <h1 className="mb-4">Register with Us!</h1>
                <div className="col-sm-8 mx-auto">
                  <Link
                    to="/registration"
                    className="btn btn-primary"
                    style={{
                      height: "60px",
                      width: "200px",
                      fontSize: "20px",
                      paddingTop: "15px",
                      borderRadius: "5px",
                    }}
                  >
                    REGISTER NOW
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-6 py-5">
              <h1 className="text-white mb-4 text-center">
                Our Future Main Building
              </h1>
              <div className="p-2 my-2 text-center">
                <img
                  src={schoolBuilding}
                  style={{ height: "300px", width: "100%", maxWidth: "400px" }}
                  alt="Main Building"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-dark text-white-50 border-top py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-left mb-3 mb-md-0">
              <p className="m-0">
                Copyright &copy;
                <a className="text-white" href="https://freewebsitecode.com/">
                  GHIBBAT2023
                </a>
                . All Rights Reserved.
              </p>
            </div>
            <div className="col-md-6 text-center text-md-right">
              <p className="m-0">
                Designed by
                <a className="text-white" href="https://freewebsitecode.com">
                  MAITS
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
